@font-face {
    font-family: MainFont;
    src: url('Dimbo.ttf');
}

:root {
    font-size: 20px;
}

@media (max-aspect-ratio: 3/2) {
    @media only screen and (max-width: 880px) {
        :root {
            font-size: 2.23vw;
        }
    }
    @media only screen and (max-width: 330px) {
        :root {
            font-size: 7.5px;
        }
    }
}
@media (min-aspect-ratio: 3/2) {
    @media only screen and (max-height: 600px) {
        :root {
            font-size: 3.75vh;
        }
    }
}


body, html {
    height: 100%;
    width: 100%;
    margin: 0;
    background: #123456;
}

#version {
    font-size: 0.75rem;
    color: #dddd22;
    position: absolute;
    bottom: 2px;
    right: 2px;
}

#mainContainer {
    font-family: MainFont;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    justify-content: center;

    #title {
        margin: 0.5rem 0rem;
        font-size: 3rem;
    }

    #front, #back {
        position: absolute;
        width: calc(100% - 10px);
        max-width: 1000px;
        height: 100%;
        margin: 5px;
        box-sizing: border-box;
        transform-style: preserve-3d;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transition: transform 2s cubic-bezier(0.645, 0.045, 0.355, 1);
        box-shadow:0 0 40px rgba(0,0,0,0.8);
    }

    #front {
        text-align: center;
        transform: rotateY(0deg);
    }
    #back {
        transform: rotateY(180deg);
    }

    &.gameStarted {
        #front {
            transform: rotateY(180deg);
        }
        #back {
            transform: rotateY(0deg);
        }
    }

    #front {
        font-size: 1.5rem;
        background: yellow;
        text-align: center;

        input:focus{
            outline: none;
        }

        #inputContainer {
            position: relative;
            width: 100%;
            max-width: 16rem;
            margin: auto;
            font-family: MainFont;

            .fancyInput {
                position: relative;
            }
            .fancyInput > input {
                width: 100%;
                height: 2rem;
                margin: 0.8rem 0rem;
                padding-left: 1rem;
                padding-top: 0.5rem;
                padding-bottom: 0rem;
                border: none;
                border-bottom: 0.15rem solid grey;
                box-sizing: border-box;
                font-size: 1.5rem;
                font-family: MainFont;
                box-shadow: 2px 2px 2px 0px black;
            }

            .fancyInput > .fancyInputLabel, {
                position: absolute;
                top: 0rem;
                left: 1rem;
                line-height: 100%;
                color: #e00000;
                transition: .2s;
            }

            .fancyInput > input:focus + .fancyInputLabel,
            .fancyInput > input:valid + .fancyInputLabel {
                top: -0.9rem;
                left: 0.5rem;
                font-size: 1rem;
                color: rgba(159, 1, 0, 0.9);
            }

            .fancyInput > input:valid + .fancyInputLabel {
                color: rgba(0, 159, 5, 0.9);
            }
        }

        #PlayerColor {
            padding: 2px;
        }
        #JoinGame {
            width: 100%;
            height: 2rem;
            margin: 0.5rem 0rem;
            border: none;
            border-bottom: 0.15em solid grey;
            box-sizing: border-box;
            font-size: 1rem;
            font-family: MainFont;
            max-width: 10rem;
            box-shadow: 2px 2px 2px 0px black;
        }

        #JoinGame:focus {
            transform: scale(0.9);
        }
    }

    canvas {
        max-width: 100%;
        max-height: 96vh;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
    }

    #touchInput {
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 100;

        &.hidden {
            display: none;
        }

        & > div {
            flex-grow: 1;
            border-right: 1px dashed #008b8b54;
        }
    }
}


